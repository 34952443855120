import React from 'react';
import { Table, Button } from 'tabler-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

import Avatar from '../layout/Avatar';

const BookingsList = ({ bookings }) => {
  const { t } = useTranslation();

  return (
    <>
      <h4>{t('dashboard.bookings.title')}</h4>
      {!bookings.length && <p>{t('dashboard.bookings.no_bookings')}</p>}

      {bookings.length > 0 && (
        <>
          <Table className="mt-3">
            <Table.Body>
              {bookings.map((booking, key) => (
                <Table.Row key={key}>
                  <Table.Col>
                    <Avatar url={booking.athlete.avatar} />
                    <span className="d-none d-md-block">
                      <span className="text-default">{`${booking.athlete.firstname} ${booking.athlete.lastname}`}</span>
                    </span>
                  </Table.Col>
                  <Table.Col>
                    <p className="mb-0">{booking.sport.name}</p>
                    <p className="mb-0">
                      {format(new Date(booking.startAt), 'd LLL y', {
                        locale: fr,
                      })}
                      ,{' '}
                      {format(new Date(booking.startAt), 'HH:mm', {
                        locale: fr,
                      })}
                    </p>
                  </Table.Col>
                  <Table.Col className="text-right">
                    <Button
                      RootComponent="a"
                      color="primary"
                      href={`tel: ${booking.athlete.phone}`}
                    >
                      {t('dashboard.bookings.contact')}
                    </Button>
                  </Table.Col>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          <hr />
          <p>{t('dashboard.bookings.cancel.explanation')}</p>
          <p
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: t('dashboard.bookings.cancel.contact', {
                email: 'help@wearehitch.com',
              }),
            }}
          />
        </>
      )}
    </>
  );
};

BookingsList.propTypes = {
  bookings: PropTypes.array,
};

export default BookingsList;
