export default {
  apiUrl: 'https://api.wearehitch.com',
  stripeClientId: 'ca_FPAsHRVR9HoULVGQMue4zUNwGvszT9M7',
  firebaseConfig: {
    apiKey: 'AIzaSyAQSk2yKdZXrHyYtHCWnTAHY-_N4IvZRwk',
    authDomain: 'hitch-prod-32b31.firebaseapp.com',
    databaseURL: 'https://hitch-prod-32b31.firebaseio.com',
    projectId: 'hitch-prod-32b31',
    storageBucket: 'hitch-prod-32b31.appspot.com',
    messagingSenderId: '308767259245',
    appId: '1:308767259245:web:6c02a271ac2b125d2b5d64',
  },
  startHours: {
    min: '05:00',
    max: '22:00',
  },
  endHours: {
    min: '06:00',
    max: '23:00',
  },
  maxFileSize: 5000000, // 5Mb in bytes
  supportedFormats: ['image/jpeg', 'image/jpg', 'image/png'],
};
